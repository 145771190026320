import { UserStatusType, LogicStatusType } from 'tccc-utils';
import * as t from 'io-ts';
import { HangupType, SessionType, UseMobileAccept, UseMobileCallOut } from '../constants/sessions';
import { Message, TcccSdk } from '../tccc';
import { ServerType } from '../store/sessions';
import { CallState } from '../socket/session-manager/session-manager';
import { AcceptedState, HungupParams } from '../socket/session-manager/member-manager';
import EventEmitter from 'eventemitter3';
import { NumberReflectMode } from '../constants/appSettings';

export interface InternalCallIn {
  sessionId: string;
  userId: string;
  timeout: number;
  type: SessionType.internal;
  serverType?: ServerType;
}
export interface InternalCallOut {
  sessionId: string;
  userId: string;
  type: SessionType.internal;
  serverType?: ServerType;
}
export interface PhoneCallIn {
  sessionId: string;
  calleePhoneNumber: string;
  callerPhoneNumber?: string;
  protectedCaller?: string;
  protectedCallee?: string;
  callerLocation?: string;
  ivrPath: { key?: string; label?: string }[];
  clientData?: string;
  members?: MemberState[];
  remark?: string;
  timeout: number;
  type: SessionType.phone;
  serverType?: ServerType;
  sessionType?: 'IVRCallOutgoing';
  aiEnabled?: boolean;

  /**
   * @deprecated 同sessionId
   */
  id: string;
  /**
   * @deprecated 来电号码
   */
  phone: string;
  /**
   * @deprecated 呼入固定为0
   */
  direction: '0';
  /**
   * @deprecated 直接用Date.now()生成
   */
  startCallTime: number;
}

export interface PhoneCallOut {
  sessionId: string;
  calleePhoneNumber: string;
  callerPhoneNumber?: string;
  calleeLocation?: string;
  remark?: string;
  type: SessionType.phone;
  serverType?: ServerType;
  aiEnabled?: boolean;
  isHost?: boolean;
  tabUUID: string;

  /**
   * @deprecated
   */
  id: string;
  /**
   * @deprecated
   */
  startCallTime: number;
  /**
   * @deprecated
   */
  direction: '1';
  /**
   * @deprecated
   */
  protectedCallee?: string;
}

export interface VoipCallIn {
  sessionId: string;
  callee: string;
  calleeRemark?: string;
  caller: string;
  callerPhoneNumber?: string;
  ivrPath: { key?: string; label?: string }[];
  nickname?: string;
  avatar?: string;
  remark?: string;
  timeout: number;
  clientData?: string;
  type: SessionType.voip;
  serverType?: ServerType;
  aiEnabled?: boolean;
}
export interface IMCallIn {
  sessionId: string;
  type: SessionType.im;
  timeout: number;
  peerSource: string;
  channelName?: string;
  clientData?: string;
  nickname?: string;
  userId: string;
  avatar?: string;
  remark?: string;
  onlineState?: boolean;
  // im会话会有im虚拟号id和是否是座席主动呼出的标识
  channelAgentID?: string;
  isIMCallOut?: number;
}

export interface VideoCallIn {
  sessionId: string;
  type: SessionType.video;
  timeout: number;
  userId: string;
  nickname?: string;
  avatar?: string;
  remark?: string;
}

export interface SensitiveWord {
  sessionId: string;
  word: string[];
}
type CallIn = InternalCallIn | PhoneCallIn | VoipCallIn | IMCallIn | VideoCallIn;
type CallOut = InternalCallOut | PhoneCallOut;

export interface CallInAccepted {
  sessionId: string;
  tabUUID: string;
  serverType?: ServerType;
  playAudio?: boolean;
  isHost?: boolean;

  /**
   * @deprecated
   */
  id: string;
  /**
   * @deprecated
   */
  ivrPath: { key?: string; label?: string }[];
  /**
   * @deprecated
   */
  userId?: string;
  /**
   * @deprecated
   */
  remark?: string;
  /**
   * @deprecated
   */
  nickname?: string;
  /**
   * @deprecated
   */
  calleePhoneNumber: string;
  /**
   * @deprecated
   */
  callerPhoneNumber?: string;
  /**
   * @deprecated
   */
  callerLocation?: string;
  /**
   * @deprecated
   */
  protectedCallee: string;
  /**
   * @deprecated
   */
  protectedCaller?: string;
  /**
   * @deprecated
   */
  direction: '0';
  /**
   * @deprecated
   */
  type: SessionType;
  /**
   * @deprecated
   */
  timeout: number;
}

export type SessionEnded = {
  sessionId: string;
  closeBy: CloseBy;
  hangupType: t.TypeOf<typeof HangupType>;
  setRest?: boolean;
  mainReason?: string;
  subReason?: string;
  callerPhoneNumber?: string; // 主叫号码，手机外呼失败时
  calleePhoneNumber?: string; // 座席号码，手机外呼失败时
} & Partial<{
  /**
   * @deprecated
   */
  id: string;
  /**
   * @deprecated
   */
  callerLocation: string;
  /**
   * @deprecated
   */
  calleeLocation: string;
  /**
   * @deprecated
   */
  direction: '0' | '1';
  /**
   * @deprecated
   */
  ivrPath: { key?: string; label?: string }[];
  /**
   * @deprecated
   */
  remark?: string;
  /**
   * @deprecated
   */
  protectedCallee: string;
  /**
   * @deprecated
   */
  protectedCaller?: string;
  /**
   * @deprecated
   */
  type: SessionType;
  /**
   * @deprecated
   */
  timeout: number;
  /**
   * @deprecated
   */
  duration: number;
  /**
   * @deprecated
   */
  serverType: ServerType;
  /**
   * @deprecated
   */
  status?: string;
}>;

export type CloseBy = 'user' | 'seat' | 'timer' | 'system';
type ConnectionState = 'disconnected' | 'connected' | 'connecting' | 'reconnecting';
type NetworkQualityType = 0 | 1 | 2 | 3 | 4 | 5 | 6;
type InviteCallState = 'accepted' | 'hangup' | 'failed';
type ReceiveIvrState = 'error' | 'timeout' | 'ok';

export type FeedbackQueue = {
  sessionId: string;
  peerSource: string;
  userId: string;
  nickname: string;
  timestamp: number;
};

export type MemberState = {
  memberId: string;
  callState: CallState;
  acceptedState: AcceptedState;
  isHost: boolean;
  isSelf: boolean; // 表示是当前座席member
  hungupParams?: HungupParams;
  userId?: string; // 座席
  waitId?: string; // queue 排队ID
  phone?: string; // 外线
  protectedPhone?: string;
  serverType: ServerType | 'outboundSeat' | 'queue' | 'customer';
  displayName?: string;
  extra: {
    skillGroupId?: string;
    callerLocation?: string;
  };
  acceptTimestamp: number;
  hungupTimestamp: number;
};

export interface TcccEvents {
  loginSuccess: (res: {
    userInfo: {
      sdkAppId: string;
      userId: string;
      nickname: string;
      staffName: string;
      staffNo: string;
      mobile: string;
      canUseMobile: boolean;
      useMobileAcceptType: UseMobileAccept;
      useMobileCallOutType: UseMobileCallOut;
      isBindMobile?: boolean;
      roleId: string;
      skillGroupId?: string[];
    };
    appSettings: {
      callTimeout: number;
      imTimeout: number;
      audioTimeout: number;
      videoTimeout: number;
      endCountdown: number;
      imEndCountdown: number;
      audioEndCountdown: number;
      imAutoAccept: boolean;
      telAutoAccept: boolean;
      audioAutoAccept: boolean;
      videoAutoAccept: boolean;
      hideCalloutNumber: boolean;
      hideCallinNumber: boolean;
      numberReflectMode: NumberReflectMode;
      telCallinPostIVR: boolean;
      seatType: 1 | 2 | 3 | 4 | 5 | 6 | 7;
      telBlackUserEnable: boolean;
      staffAssignedCalloutNumber: boolean;
      realtimeAsr: boolean;
      autoRealtimeAsr: boolean;
      imContactUserEnable: boolean;
    };
    agent: Partial<{
      assignCall: boolean;
      assignIM: boolean;
      existCall: boolean;
      existIM: boolean;
    }>;
    extra?: any;
  }) => void;

  agentStateChanged: (res: {
    status?: UserStatusType;
    logicStatus: LogicStatusType;
    restReason?: string;
    nextStatus?: UserStatusType;
    IM?: {
      total: number;
      used: number;
    };
    queueCount?: Record<string, number>;
  }) => void;
  callInAccepted: (res: CallInAccepted) => void;
  calloutAccepted: (res: { sessionId: string; isHost?: boolean }) => void;
  callIn: (res: CallIn) => void;
  callOut: (res: CallOut) => void;
  sessionEnded: (res: SessionEnded) => void;

  // ivr
  ivrSoundPlayFinished: (res: { sessionId: string }) => void;
  // 外线
  inviteCallStateChanged: (res: { sessionId: string; state: InviteCallState }) => void;
  // 收号
  receiveIvrStateChanged: (res: {
    sessionId: string;
    state: ReceiveIvrState;
    clientData?: string;
    keyPressed?: string;
  }) => void;
  // 会议相关通知
  memberStateChanged: (res: MemberState & { sessionId: string }) => void;

  // ws event
  kickedOut: () => void;
  forcedOffline: () => void;
  expired: () => void;
  // error: () => void;
  connectionStateChanged: (res: { state: ConnectionState }) => void;
  userSettingsUpdated: (
    res: Partial<{
      useMobileAcceptType: UseMobileAccept;
      useMobileCallOutType: UseMobileCallOut;
    }>,
  ) => void;

  // trtc event
  networkQuality: (res: {
    uplinkNetworkQuality: NetworkQualityType;
    downlinkNetworkQuality: NetworkQualityType;
    uplinkRTT: number;
    downlinkRTT: number;
    uplinkLoss: number;
    downlinkLoss: number;
  }) => void;
  audioVolume: (res: { audioVolume: number; sessionId: string; userId: string }[]) => void;
  muted: (data: { sessionId: string; audio: boolean }) => void;
  unmuted: (data: { sessionId: string; audio: boolean }) => void;

  // asr
  asrEvent: (res: { sessionId: string; flow: 'IN' | 'OUT'; result: any }) => void;

  // chat
  messageReceived: (res: { data: Message[] }) => void;
  ratingSucceeded: (res: { sessionId: string; data: string; message: Message }) => void;
  imReadyStateChanged: (res: { state: 'disconnected' | 'connected' | 'init' }) => void;
  typingStateChanged: (event: { sessionId: string; state: boolean }) => void;
  onlineStateChanged: (event: { sessionId: string; state: boolean }) => void;
  feedbackQueueChanged: (res: FeedbackQueue[]) => void;
  onMessageRevoked: (res: { data: { sessionId: string; messageId: string }[] }) => void;
  onMessageModified: (res: { data: { sessionId: string; message: Message }[] }) => void;
  // sdk发送消息通知sdk-v2上屏
  sendCustomerMessage: (res: { message: Message }) => void;

  // chat media
  mediaInvited: (res: { sessionId: string; type: 'audio' | 'video' }) => void;
  mediaAccepted: (res: { sessionId: string }) => void;
  mediaEnded: (res: { sessionId: string }) => void;
  mediaRejected: (res: { sessionId: string }) => void;
}

/**
 * 兼容官网版本的事件列表
 */
export interface ExternalTcccEvents
  extends Pick<TcccEvents, 'callIn' | 'sessionEnded' | 'calloutAccepted' | 'memberStateChanged' | 'networkQuality'> {
  ready: (res: { tabUUID: string }) => void;
  userAccessed: TcccEvents['callInAccepted'];
  autoTransfer: (res: { sessionId: string }) => void;
  callOuted: TcccEvents['callOut'];
  transfer: (res: { sessionId: string }) => void;
  statusChanged: (
    res: Pick<Parameters<TcccEvents['agentStateChanged']>[0], 'status' | 'logicStatus' | 'restReason'>,
  ) => void;
  asr: TcccEvents['asrEvent'];

  /**
   * 没有在官网，明确有客户在用的事件，后续都会上官网
   */
  sessionCompleted: (res: { sessionId: string }) => {};
  tokenExpired: (err: Error) => void;
  kickedOut: () => void;
  forcedOffline: () => void;
  userChanged: (res: { sessionId: string; callInfo: object }) => void;
}

type VoidFunc = () => void;
/**
 * 旧版本iframe事件列表
 */
export interface IframeSdkEvents {
  callIn: VoidFunc;
  userAccessed: VoidFunc;
  sessionEnded: VoidFunc;
  sessionCompleted: VoidFunc;
  transfer: VoidFunc;
  userChanged: VoidFunc;
  imMessage: VoidFunc;
  imMessagePosted: VoidFunc;
  callOuted: VoidFunc;
  calloutAccepted: VoidFunc;
  statusChanged: VoidFunc;
  autoTransfer: VoidFunc;
  dialogStateChanged: VoidFunc;
  ready: VoidFunc;
  receiveStatusChanged: VoidFunc;
  liveLocalLevel: VoidFunc;
  networkQuality: VoidFunc;
  tokenExpired: VoidFunc;
  messageReceived: VoidFunc;
  onlineStateChanged: VoidFunc;
  sendMessage: VoidFunc;
  userSettingsUpdated: VoidFunc;
  kickedOut: VoidFunc;
  forcedOffline: VoidFunc;
  asr: VoidFunc;
  memberStateChanged: VoidFunc;
}

/**
 * 客户不应该用到的事件列表
 */
export type WarnTcccEvents = Pick<IframeSdkEvents, keyof ExternalTcccEvents>;

/**
 * 兼容旧版本事件，直接重新触发
 */
export const CompatibleEvents = (ev: EventEmitter<TcccEvents & ExternalTcccEvents>, tccc: TcccSdk) => {
  let { status, logicStatus, reason } = tccc.Agent.agentState;
  ev.on('asrEvent', (...args) => {
    ev.emit('asr', ...args);
  });
  ev.on('callInAccepted', (...args) => {
    ev.emit('userAccessed', ...args);
  });
  ev.on('sessionEnded', ({ closeBy, sessionId }) => {
    if (closeBy === 'timer') {
      ev.emit('autoTransfer', { sessionId });
    }
  });
  ev.on('agentStateChanged', (args) => {
    if (
      // @ts-ignore
      (args.status && status !== args.status) ||
      (args.logicStatus && args.logicStatus !== args.logicStatus) ||
      args.restReason !== reason
    ) {
      // @ts-ignore
      status = args.status;
      // @ts-ignore
      logicStatus = args.logicStatus;
      reason = args.restReason;
      // @ts-ignore
      ev.emit('statusChanged', { status, logicStatus, restReason: reason });
    }
  });
  ev.on('callOut', (...args) => {
    ev.emit('callOuted', ...args);
  });
};
