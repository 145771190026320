import EventEmitter from 'eventemitter3';
import { GET_API_BAK_DOMAIN, GET_API_DOMAIN } from 'tccc-env/src/url';
import Logger from 'tccc-logger';
import TcccWebsocket from 'tccc-ws';
import { TcccWebsocketEvent } from 'tccc-ws/src/platform/TcccWebsocket';
import { ulid } from 'ulidx';
import { SocketMessage } from '../socket';
import { request } from './http';

const isInternational = self.name === 'tccc-worker-intl';
const defaultLoggerParams = {
  logLevel: import.meta.env?.DEV || process.env.NODE_ENV === 'development' ? 0 : 17,
  url: `https://${isInternational ? 'otlp.connect.tencentcloud.com' : 'otlp.tccc.qcloud.com'}/v1/logs`,
  id: isInternational ? 'tcccsg' : 'tccc',
  name: self.name,
};

type LoginMessage = {
  command: 'login';
  data: {
    userId: string;
    sdkAppId: string;
    sessionKey: string;
    tabUUId: string;
    origin: string;
    language: string;
  };
  nonce: string;
};
type LogoutMessage = {
  command: 'logout';
  data: any;
  nonce: string;
};

type SetStatusMessage = {
  command: 'setStatus';
  data: any;
  nonce: string;
};

type S2CMessageType = {
  type: string;
  data: any;
  nonce: string;
};
type AliveMessageType = {
  command: 'alive';
  data: undefined;
  nonce: string;
};

type CallMessageType = {
  command: 'call';
  data: any;
  nonce: string;
};

type AcceptMessageType = {
  command: 'accept';
  data: any;
  nonce: string;
};
type SetLanguageMessageType = {
  command: 'setLanguage';
  data: {
    language: string;
  };
  nonce: string;
};

export type PortMessageType =
  | LoginMessage
  | LogoutMessage
  | SetStatusMessage
  | S2CMessageType
  | AliveMessageType
  | CallMessageType
  | AcceptMessageType
  | SetLanguageMessageType;

const MAX_UNHEALTHY_COUNT = 3;

export class WorkerPort {
  port: MessagePort;
  sessionKey?: string;
  unhealthyCount = 0;
  portUUID = ulid();
  logger: Logger;
  ws: PortTcccWebsocket | null;
  nonceEvent = new EventEmitter();
  constructor({ port, workerUUID }: { port: MessagePort; workerUUID: string }) {
    this.ws = null;
    this.port = port;
    this.logger = new Logger({
      ...defaultLoggerParams,
      attributes: {
        workerUUID,
        portUUID: this.portUUID,
      },
    });
    this.logger.info('new worker port');
    this.port.addEventListener('message', this.onPortMessage);
    this.port.start();
  }

  onPortMessage = (event: MessageEvent<PortMessageType>) => {
    if ('command' in event.data) {
      const { command } = event.data;
      const methodsMap: Record<Exclude<PortMessageType, S2CMessageType>['command'], Function> = {
        login: this.login,
        logout: this.logout,
        setStatus: this.setStatus,
        alive: this.alive,
        call: this.call,
        accept: this.accept,
        setLanguage: this.setLanguage,
      };
      try {
        methodsMap[command](event);
      } catch (e: any) {
        this.logger.error(`handle port message error ${e.message}`);
      }
    } else {
      this.nonceEvent.emit(event.data.nonce);
    }
  };
  postPortMessage(data: { type: string; data: any; nonce: string }) {
    const timer = setTimeout(() => {
      this.logger.warn('client resolve message timeout');
      this.unhealthyCount = this.unhealthyCount + 1;
      if (this.unhealthyCount > MAX_UNHEALTHY_COUNT) {
        this.kickPort();
      }
    }, 5000);
    this.nonceEvent.once(data.nonce, () => {
      clearTimeout(timer);
      // TODO: clear client
    });
    this.port.postMessage(data);
  }
  /**
   * 回应client
   */
  resolvePortMessage(event: MessageEvent<Exclude<PortMessageType, S2CMessageType>>, data?: any) {
    const { nonce, command } = event.data;
    this.port.postMessage({ nonce, data: data || 'ok', command });
  }
  rejectPortMessage(event: MessageEvent<Exclude<PortMessageType, S2CMessageType>>, error: Error) {
    const { nonce, command } = event.data;
    this.port.postMessage({ nonce, data: error, command });
  }

  private kickPort = () => {
    this.logger.info({
      msg: 'kick port',
    });
    if (this.ws) {
      this.ws.logout('unhealthy', this.port);
      this.removeListener(this.ws);
      this.ws = null;
    } else {
      this.logger.info('kick port before login');
    }
  };

  private login = (event: MessageEvent<LoginMessage>) => {
    const { data } = event.data;
    this.logger.setAttributes({
      userId: data.userId,
      sdkAppId: data.sdkAppId,
    });
    this.sessionKey = data.sessionKey;
    this.logger.info({
      msg: JSON.stringify(data),
      attributes: {
        sessionKey: data.sessionKey,
      },
    });
    this.ws = PortTcccWebsocket.getInstance({
      sdkAppId: data.sdkAppId,
      userId: data.userId,
      logger: this.logger,
      port: this.port,
      origin: data.origin,
      language: data.language,
    });
    this.addListener(this.ws);
    this.ws
      .login({
        sdkAppId: data.sdkAppId,
        userId: data.userId,
        sessionKey: data.sessionKey,
        tabUUId: data.tabUUId,
        port: this.port,
      })
      .then((res) => {
        if (res && typeof res !== 'string') {
          this.postPortMessage({ type: 'connect', data: undefined, nonce: ulid() });
          this.postPortMessage({ type: 'heartbeat', data: { message: res, tabUUID: '' }, nonce: ulid() });
        }
        this.resolvePortMessage(event);
      })
      .catch((err) => {
        this.rejectPortMessage(event, err);
      });
  };
  private logout = (event: MessageEvent<LogoutMessage>) => {
    const { data } = event.data;
    this.logger.info({
      msg: JSON.stringify(data),
    });
    if (this.ws) {
      this.ws.logout(data, this.port);
      this.removeListener(this.ws);
      this.ws = null;
      this.resolvePortMessage(event);
      // 单个port logout
    } else {
      this.logger.info('logout before login');
      this.resolvePortMessage(event);
    }
  };
  private setStatus = (event: MessageEvent<SetStatusMessage>) => {
    const { data } = event.data;
    this.logger.info({
      msg: JSON.stringify(data),
    });
    this.ws
      ?.setStatus(data.status, data.restReason)
      .then((res) => {
        this.resolvePortMessage(event, res);
      })
      .catch((err) => {
        this.rejectPortMessage(event, err);
      });
  };
  private alive = (event: MessageEvent<AliveMessageType>) => {
    this.resolvePortMessage(event);
  };

  private call = (event: MessageEvent<CallMessageType>) => {
    if (!this.ws) {
      return this.rejectPortMessage(event, new Error('worker not ready'));
    }
    this.ws
      .call({
        port: this.port,
        data: event.data,
        sessionKey: this.sessionKey!,
      })
      .then((res) => {
        this.resolvePortMessage(event, res);
      })
      .catch((err) => {
        this.rejectPortMessage(event, err);
      });
  };
  private accept = (event: MessageEvent<AcceptMessageType>) => {
    if (!this.ws) {
      return this.rejectPortMessage(event, new Error('worker not ready'));
    }
    this.ws
      .accept({
        port: this.port,
        data: event.data,
        sessionKey: this.sessionKey!,
      })
      .then((res) => {
        this.resolvePortMessage(event, res);
      })
      .catch((err) => {
        this.rejectPortMessage(event, err);
      });
  };

  private setLanguage = (event: MessageEvent<SetLanguageMessageType>) => {
    if (!this.ws) {
      return this.rejectPortMessage(event, new Error('worker not ready'));
    }
    const { data } = event.data;
    this.ws.setLanguage(data.language);
    this.resolvePortMessage(event);
  };

  private removeListener = (ws: PortTcccWebsocket) => {
    ws.off('connect', this.onConnect);
    ws.off('disconnect', this.onDisConnect);
    ws.off('s2c', this.onS2C);
    ws.off('heartbeat', this.onHeartbeat);
  };

  private addListener = (ws: PortTcccWebsocket) => {
    ws.on('connect', this.onConnect);
    ws.on('disconnect', this.onDisConnect);
    ws.on('s2c', this.onS2C);
    ws.on('heartbeat', this.onHeartbeat);
  };

  private onConnect = () => {
    this.postPortMessage({ type: 'connect', data: undefined, nonce: ulid() });
  };
  private onDisConnect = (e: Parameters<TcccWebsocketEvent<SocketMessage>['disconnect']>[0]) => {
    this.postPortMessage({
      type: 'disconnect',
      data: e,
      nonce: ulid(),
    });
  };
  private onS2C = (data: SocketMessage, tabUUId: string) => {
    this.postPortMessage({
      type: 's2c',
      data: {
        message: data,
        tabUUId,
      },
      nonce: data.nonce,
    });
  };
  private onHeartbeat = (data: SocketMessage, tabUUID?: string) => {
    this.postPortMessage({
      type: 'heartbeat',
      data: {
        message: data,
        tabUUID,
      },
      nonce: data.nonce,
    });
  };
}

/**
 * 管理多个port的ws，支持单port logout
 feat: 改造成 port + s2c事件的功能
 */
class PortTcccWebsocket extends EventEmitter<TcccWebsocketEvent<SocketMessage>> {
  private static wsInstance = new Map<string, PortTcccWebsocket>();
  public static getInstance = ({
    userId,
    sdkAppId,
    logger,
    port,
    origin,
    language,
  }: {
    userId: string;
    sdkAppId: string;
    logger: Logger;
    port: MessagePort;
    origin: string;
    language: string;
  }): PortTcccWebsocket => {
    let instance = PortTcccWebsocket.wsInstance.get(sdkAppId + userId);
    if (!instance) {
      const baseURL = GET_API_DOMAIN(origin);
      const backupURL = GET_API_BAK_DOMAIN(origin);
      instance = new PortTcccWebsocket({
        url: `wss://${baseURL}/staff`,
        backupUrl: `wss://${backupURL}/staff`,
        logger,
        port,
        origin,
        language,
      });
      PortTcccWebsocket.wsInstance.set(sdkAppId + userId, instance);
    }
    instance.addPort(port);
    return instance;
  };
  ports: Set<MessagePort>;
  lockPort: MessagePort | null;
  portIDMap: Map<MessagePort, string>;
  logger: Logger;
  ws: TcccWebsocket<SocketMessage>;
  loginParams: (Parameters<TcccWebsocket<SocketMessage>['login']>[0] & { tabUUId: string }) | null;
  origin: string;

  constructor(params: ConstructorParameters<typeof TcccWebsocket>[0] & { port: MessagePort; origin: string }) {
    super();
    this.lockPort = null;
    this.logger = new Logger({
      ...defaultLoggerParams,
      attributes: {},
    });
    this.origin = params.origin;
    this.loginParams = null;
    this.ws = new TcccWebsocket(params);
    this.addPortListener();
    this.ports = new Set<MessagePort>();
    this.portIDMap = new Map();
  }

  public login(params: Parameters<TcccWebsocket<SocketMessage>['login']>[0] & { port: MessagePort; tabUUId: string }) {
    this.logger.info('port login');
    this.loginParams = {
      userId: params.userId,
      sdkAppId: params.sdkAppId,
      sessionKey: params.sessionKey,
      tabUUId: params.tabUUId,
    };
    this.addPort(params.port);
    this.portIDMap.set(params.port, params.tabUUId);
    return this.ws.login(params);
  }

  public logout(reason = 'logout', port: MessagePort) {
    this.delPort(port);
    this.logger.info('port logout');
    if (this.loginParams?.sdkAppId && this.loginParams.userId) {
      this.logger.debug({
        msg: 'port logout',
        attributes: {
          size: this.ports.size,
        },
      });
      if (this.ports.size === 0) {
        this.logger.info('real logout');
        this.ws.logout(reason);
      } else {
        this.logger.info('ignore logout');
      }
    } else {
      // 未登录
    }
  }

  public setStatus(...params: Parameters<TcccWebsocket<SocketMessage>['setStatus']>) {
    return this.ws.setStatus(...params);
  }

  public call = ({ data, sessionKey, port }: { port: MessagePort; data: CallMessageType; sessionKey: string }) => {
    this.lockPort = port;
    const isDialBack = 'sessionId' in data.data.callee;
    return request(
      isDialBack ? '/ccc/pstn/dialBack' : '/ccc/pstn/dial',
      isDialBack
        ? {
            sessionId: data.data.callee.sessionId,
          }
        : data.data,
      {
        sessionKey,
        origin: this.origin,
      },
    );
  };

  public accept = ({ data, sessionKey, port }: { port: MessagePort; data: AcceptMessageType; sessionKey: string }) => {
    this.lockPort = port;
    return request(
      '/ccc/pstn/seatin',
      {
        sessionId: data.data.sessionId,
      },
      {
        sessionKey,
        origin: this.origin,
      },
    );
  };

  public setLanguage = (language: string) => {
    this.ws.setLanguage(language);
  };

  on<T extends EventEmitter.EventNames<TcccWebsocketEvent<SocketMessage>>>(
    event: T,
    fn: EventEmitter.EventListener<TcccWebsocketEvent<SocketMessage>, T>,
    context?: any,
  ): this {
    return super.on(event, fn, context);
  }

  protected addPort(port: MessagePort) {
    this.ports.add(port);
    this.logger.info({
      msg: 'addPort',
      attributes: {
        size: this.ports.size,
      },
    });
  }
  protected delPort(port: MessagePort) {
    this.ports.delete(port);
    this.logger.info({
      msg: 'delPort',
      attributes: {
        size: this.ports.size,
      },
    });
  }

  private addPortListener() {
    this.ws.on('connect', () => {
      this.emit('connect');
    });
    this.ws.on('disconnect', (msg) => {
      this.emit('disconnect', msg);
    });
    this.ws.on('failed', (msg) => {
      this.emit('failed', msg);
    });
    this.ws.on('heartbeat', (msg) => {
      this.emit('heartbeat', msg);
    });
    this.ws.on('s2c', (msg) => {
      const tabUUId = this.lockPort ? this.portIDMap.get(this.lockPort) : '';
      this.emit('s2c', msg, tabUUId);
    });
  }
}
