import * as t from 'io-ts';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import isObject from 'lodash/isObject';
import toString from 'lodash/toString';
import { ServerType, Session, UnifiedTRTCParams } from './index';
import { Direction } from '../../constants/sessions';
import { getOrPromiseReject, stringFromNumber, timer, PHONE_REGEXP } from 'tccc-utils';
import { awaitTime } from '../../utils/awaitTime';
import { NumberReflectMode } from '../../constants/appSettings';
import { enterRoom, leaveRoom, muteMic, unmuteMic } from './trtc.thunk';
import { getLogger } from '../../common/Logger';
import { checkDevice } from '../../tccc/Devices';
import { TcccSdk } from '../../tccc';
import { pstnAPIs } from '../../http/apis/pstn';
import { giveUpSession, SessionIdParams } from './sessions.thunk';
import traceContext, { contextWithSpan } from '../../http/tracer';
import { InvalidParamsError, NotFoundError } from '../../common/TcccError';
import i18next from '../../i18n/i18next.config';
import { innerEmitter } from '../../utils/innerEmitter';
import { Customer, SessionManager, statusC } from '../../socket/session-manager/session-manager';
import { createAsyncThunk, TCCCError, unwrapResult } from '../createAsyncThunk';

export const StartOutboundCallParams = t.intersection([
  t.type({ phoneNumber: t.string }),
  t.partial({
    remark: t.string,
    phoneDesc: t.string,
    uui: t.string,
    skillGroupId: t.string,
    callerPhoneNumber: t.string,
    servingNumberGroupIds: t.array(t.string),
    phoneEncodeType: t.union([t.literal('reflect'), t.literal('base64'), t.literal('number')]),
  }),
]);
const startOutboundCallTag = 'call/startOutboundCall';
export const startOutboundCall = createAsyncThunk(
  startOutboundCallTag,
  async (params: t.TypeOf<typeof StartOutboundCallParams> & { emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo || !params.emitter.socket) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const logger = getLogger(params.emitter.Agent.userInfo);
    try {
      await checkDevice(params.emitter);
    } catch (e) {
      logger.info('checkDevice error', e);
      throw e;
    }
    const manualSpan = traceContext.tracer.startSpan('tccc.Call.startOutboundCall', {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { traceId, spanId } = manualSpan.spanContext();
    logger.custom(
      {
        traceId,
        spanId,
        level: 'info',
        ...omitBy(params, isObject),
      },
      '[API] tccc.Call.startOutboundCall',
    );
    const { numberReflectMode, hideCalloutNumber, realtimeAsr } = params.emitter.Agent.settings;
    const callee: { phone: string; phoneEncodeType?: 'base64' | 'reflect' | 'number'; remark?: string } = { phone: '' };
    const calleeInfo = { calleePhoneNumber: '', protectedCallee: '' };
    const extra: {
      uui?: string;
      skillGroupId?: string;
      servingNum?: string;
      servingNumberGroupIds?: string[];
    } = {};
    const {
      remark: resRemark,
      phoneDesc,
      phoneNumber,
      ...extraArgs
    } = await getOrPromiseReject(StartOutboundCallParams, manualSpan)(params);
    if (!phoneNumber) {
      const error = new InvalidParamsError('phoneNumber', phoneNumber, manualSpan);
      manualSpan.end();
      throw error;
    }
    callee.remark = phoneDesc || resRemark;
    extra.uui = extraArgs.uui;
    extra.servingNum = extraArgs.callerPhoneNumber;
    extra.servingNumberGroupIds = extraArgs.servingNumberGroupIds;
    if (extraArgs.skillGroupId) {
      extra.skillGroupId = `${extraArgs.skillGroupId}`;
    }
    if (params.phoneEncodeType) {
      // 调用方传入编码类型，优先级最高
      callee.phoneEncodeType = params.phoneEncodeType;
      switch (params.phoneEncodeType) {
        case 'reflect':
          if ([NumberReflectMode.partial, NumberReflectMode.global].includes(numberReflectMode)) {
            // 调用方式指定reflect类型，也要开启才能生效
            callee.phone = phoneNumber;
            calleeInfo.protectedCallee = phoneNumber;
          } else {
            // 否则reflect类型不生效，抛错
            throw new InvalidParamsError('phoneEncodeType', params.phoneEncodeType);
          }
          break;
        case 'number':
          // 直接明文处理
          calleeInfo.calleePhoneNumber = `${phoneNumber}`;
          callee.phone = phoneNumber;
          delete callee.phoneEncodeType;
          break;
        case 'base64':
          // 调用方指定base64类型
          calleeInfo.calleePhoneNumber = `${phoneNumber}`;
          try {
            callee.phone = btoa(`${phoneNumber}`);
          } catch (e) {
            logger.error('transform phone to base64 error', e);
            throw new InvalidParamsError('phoneNumber', params.phoneNumber);
          }
          break;
        default:
          throw new InvalidParamsError('phoneEncodeType', params.phoneEncodeType);
      }
    } else {
      // 调用方没有传入，读全局配置
      if ([NumberReflectMode.partial, NumberReflectMode.global].includes(numberReflectMode)) {
        callee.phone = phoneNumber;
        callee.phoneEncodeType = 'reflect';
        calleeInfo.protectedCallee = phoneNumber;
      } else {
        callee.phone = `${phoneNumber.replace(PHONE_REGEXP, '')}`;
        calleeInfo.calleePhoneNumber = `${phoneNumber}`;
        if (hideCalloutNumber) {
          // 对呼出做base64
          callee.phoneEncodeType = 'base64';
          try {
            callee.phone = btoa(`${phoneNumber}`);
          } catch (e) {
            logger.error('transform phone to base64 error', e);
            throw new InvalidParamsError('phoneNumber', params.phoneNumber);
          }
        }
        // 呼入的base64在SDK侧不感知
      }
    }
    if (extra.servingNum) {
      extra.servingNum = `${extra.servingNum}`;
    }
    if (extra.servingNumberGroupIds && Array.isArray(extra.servingNumberGroupIds)) {
      extra.servingNumberGroupIds = extra.servingNumberGroupIds?.filter((id) => id);
      // 有传入号码分组
      if (extra.servingNumberGroupIds?.length) {
        let numberGroupList = null;
        try {
          const getNumberGroupListAPI = '/tcccadmin/num/getNumberGroupList';
          const { numGroupList = [] } = await contextWithSpan(manualSpan, () =>
            params.emitter.http.request(getNumberGroupListAPI, { pageSize: '10000', pageNum: '0' }),
          );
          numberGroupList = numGroupList;
          // 管理后台必须已有号码分组配置
          if (!numberGroupList?.length) {
            const error = new InvalidParamsError('servingNumberGroupIds', extra.servingNumberGroupIds, manualSpan);
            manualSpan.end();
            throw error;
          }
        } catch (e) {
          manualSpan.recordException(e as Error);
          manualSpan.end();
          throw e;
        }
        const existedIds = numberGroupList.map(({ groupId }: { groupId: string }) => groupId);
        for (const idPassedIn of extra.servingNumberGroupIds) {
          // 所有传入的号码分组ID必须已存在
          if (!existedIds.includes(idPassedIn)) {
            logger.info('servingNumberGroupIds not found');
            const error = new InvalidParamsError('servingNumberGroupIds', extra.servingNumberGroupIds, manualSpan);
            manualSpan.end();
            throw error;
          }
          const targetNumberGroup = numberGroupList.find(({ groupId }) => groupId === idPassedIn)!;
          // 所有传入的号码分组内必须已有号码存在
          if (+targetNumberGroup.numberCount <= 0) {
            logger.info('callout number count is empty');
            const error = new InvalidParamsError('servingNumberGroupIds', extra.servingNumberGroupIds, manualSpan);
            manualSpan.end();
            throw error;
          }
          // 所有传入的号码分组内必须有可呼出号码存在
          if (+targetNumberGroup.canCalloutCount <= 0) {
            logger.info('can callout count is empty');
            const error = new InvalidParamsError('servingNumberGroupIds', extra.servingNumberGroupIds, manualSpan);
            manualSpan.end();
            throw error;
          }
          // 所有传入的号码分组内必须有可呼出号码存在
          if (+targetNumberGroup.canCalloutCount !== +targetNumberGroup.numberCount) {
            logger.info('can callout count is diff with number count');
            const error = new InvalidParamsError('servingNumberGroupIds', extra.servingNumberGroupIds, manualSpan);
            manualSpan.end();
            throw error;
          }
          continue;
        }
      }
    }
    const s2cResponse = await params.emitter.socket.call(callee, extra, manualSpan);
    const { remark, callerPhoneNumber, calleePhoneNumber, calleeLocation, sessionId, serverType } = s2cResponse;
    const session: Session = {
      sessionId,
      remark,
      callerPhoneNumber,
      calleePhoneNumber,
      protectedCallee: calleeInfo.protectedCallee,
      protectedCaller: '',
      calleeLocation,
      direction: Direction.callOut,
      status: '100',
      type: 'phone',
      serverType,
    };

    manualSpan.setAttributes({ serverType: session.serverType || 'staffSeat' });
    logger.custom(
      {
        traceId,
        spanId,
        level: 'info',
        session: toString(session),
      },
      '[API] tccc.Call.startOutboundCall success',
    );
    manualSpan.end();

    let aiEnabled = realtimeAsr;
    if (session.serverType && session.serverType !== 'staffSeat') {
      aiEnabled = false;
    }

    params.emitter.Call.upsertOne({ ...session, aiEnabled });
    return {
      ...session,
      aiEnabled,
    };
  },
);

const DialBackParams = t.intersection([t.type({ sessionId: t.string }), t.partial({ remark: t.string })]);
export const dialBack = createAsyncThunk(
  'call/dialBack',
  async (params: t.TypeOf<typeof DialBackParams> & { emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo || !params.emitter.socket) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const logger = getLogger(params.emitter.Agent.userInfo);
    await checkDevice(params.emitter);
    const spanName = 'tccc.Call.startOutboundCall';
    const manualSpan = traceContext.tracer.startSpan(spanName, {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { spanId, traceId } = manualSpan.spanContext();
    logger.custom(
      {
        spanId,
        traceId,
        level: 'info',
      },
      `[API] ${spanName}`,
    );
    const { realtimeAsr } = params.emitter.Agent.settings;
    const { sessionId: targetSessionId } = await getOrPromiseReject(DialBackParams, manualSpan)(params);
    if (!targetSessionId) {
      const error = new InvalidParamsError('sessionId', targetSessionId, manualSpan);
      manualSpan.end();
      throw error;
    }
    const s2cResponse = await params.emitter.socket.call({ sessionId: targetSessionId }, {}, manualSpan);
    const { remark, calleePhoneNumber, callerPhoneNumber, protectedCallee, calleeLocation, sessionId, serverType } =
      s2cResponse;
    const session: Session = {
      sessionId,
      remark,
      callerPhoneNumber,
      calleePhoneNumber,
      protectedCallee,
      calleeLocation,
      direction: Direction.callOut,
      status: '100',
      type: 'phone',
      serverType,
    };
    manualSpan.setAttributes({ serverType: session.serverType || 'staffSeat' });
    manualSpan.end();

    logger.custom(
      {
        traceId,
        spanId,
        level: 'info',
        session: toString(session),
      },
      '[API] tccc.Call.startOutboundCall success',
    );
    let aiEnabled = realtimeAsr;
    if (session.serverType && session.serverType !== 'staffSeat') {
      aiEnabled = false;
    }
    params.emitter.Call.upsertOne({ ...session, aiEnabled });

    return {
      ...session,
      aiEnabled,
    };
  },
);

export const hold = createAsyncThunk(
  'call/hold',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo || !params.emitter.socket) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const logger = getLogger(params.emitter.Agent.userInfo);
    logger.custom(
      {
        level: 'info',
        sessionId: params.sessionId,
      },
      '[API] tccc.Call.hold',
    );
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    try {
      await params.emitter.http.request('/ccc/pstn/onHold', {
        sessionId,
      });
    } catch (e) {
      const error = e as TCCCError;
      logger.custom(
        {
          level: 'error',
          sessionId: params.sessionId,
          message: error.message,
          code: error.code,
        },
        '[API] tccc.Call.hold failed',
      );
      throw error;
    }
    logger.custom(
      {
        level: 'info',
        sessionId: params.sessionId,
      },
      '[API] tccc.Call.hold success',
    );
    return {
      sessionId,
    };
  },
);

export const unHold = createAsyncThunk(
  'call/unHold',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo || !params.emitter.socket) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const logger = getLogger(params.emitter.Agent.userInfo);
    logger.custom(
      {
        level: 'info',
        sessionId: params.sessionId,
      },
      '[API] tccc.Call.unHold',
    );
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    try {
      await params.emitter.http.request('/ccc/pstn/offHold', {
        sessionId,
      });
    } catch (e) {
      const error = e as TCCCError;
      logger.custom(
        {
          level: 'error',
          sessionId: params.sessionId,
          message: error.message,
          code: error.code,
        },
        '[API] tccc.Call.unHold failed',
      );
      throw error;
    }
    logger.custom(
      {
        level: 'info',
        sessionId: params.sessionId,
      },
      '[API] tccc.Call.unHold success',
    );
    return {
      sessionId,
    };
  },
);

export const EndCallParams = t.intersection([
  t.type({
    sessionId: t.string,
    closeBy: t.union([t.literal('seat'), t.literal('event')]), // seat表示主动调用API挂断，event表示从s2c来，不需要再次调用pstn/end
  }),
  t.partial({
    error: t.union([t.boolean, t.string]),
  }),
]);

export const endCall = createAsyncThunk(
  'call/end',
  async (params: t.TypeOf<typeof EndCallParams> & { emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    timer.off(params.sessionId);
    const spanName = 'tccc.Call.end';
    const manualSpan = traceContext.tracer.startSpan(spanName, {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { spanId, traceId } = manualSpan.spanContext();
    logger.custom(
      {
        spanId,
        traceId,
        level: 'info',
        sessionId: params.sessionId,
        closeBy: params.closeBy === 'seat' ? 'API' : 'EVENTS',
      },
      `[${params.closeBy === 'seat' ? 'API' : 'EVENTS'}] ${spanName}`,
    );
    const { sessionId, error } = await getOrPromiseReject(EndCallParams, manualSpan)(params);
    const session = params.emitter.Call.selectOne(sessionId);
    const data: t.TypeOf<typeof pstnAPIs['/ccc/pstn/end']['Input']> = {
      sessionId,
    };
    if (typeof error !== 'undefined') {
      data.error = error;
    }

    manualSpan.addEvent('serverType', {
      serverType: session?.serverType || 'staffSeat',
    });

    try {
      if (params.closeBy === 'seat') {
        await contextWithSpan(manualSpan, () =>
          Promise.race([params.emitter.http.request('/ccc/pstn/end', data), awaitTime(5000)]),
        );
      }
    } catch (e) {
      manualSpan.recordException(e as Error);
      logger.error(`[API] ${spanName} failed, try again`, e);
      // 结论：座席端的愿意是无条件需要挂断成功，但我们的场景是因为和服务端有交互，所以挂断是不一定成功的。
      // 讨论结论是兼容座席愿意，程序帮他重试pstn/end增加成功概率，如果还是失败了也告知座席挂断失败了，让他 重试或者让用户侧挂断或者等待用户侧挂断
      try {
        if (params.closeBy === 'seat') {
          await contextWithSpan(manualSpan, () =>
            Promise.race([params.emitter.http.request('/ccc/pstn/end', data), awaitTime(5000)]),
          );
          logger.info(`[API] again ${spanName} success`);
        }
      } catch (e) {
        manualSpan.recordException(e as Error);
        logger.error(`[API] ${spanName} failed, try again`, e);
        // 结论：座席端的愿意是无条件需要挂断成功，但我们的场景是因为和服务端有交互，所以挂断是不一定成功的。
        // 讨论结论是兼容座席愿意，程序帮他重试pstn/end增加成功概率，如果还是失败了也告知座席挂断失败了，让他 重试或者让用户侧挂断或者等待用户侧挂断
        try {
          if (params.closeBy === 'seat') {
            await contextWithSpan(manualSpan, () =>
              Promise.race([params.emitter.http.request('/ccc/pstn/end', data), awaitTime(5000)]),
            );
            logger.info(`[API] again ${spanName} success`);
          }
        } catch (againEx) {
          manualSpan.recordException(againEx as Error);
          manualSpan.end();
          logger.error(`[API] again ${spanName} failed`, e);
          throw againEx;
        }
      }
    }

    if (session && (!session.serverType || session.serverType === 'staffSeat')) {
      try {
        await leaveRoom({ sessionId, parentSpan: manualSpan, emitter: params.emitter });
      } catch (e) {
        manualSpan.recordException(e as Error);
        manualSpan.setAttributes({
          'error.name': (e as Error).name,
          'error.message': (e as Error).message,
        });
        manualSpan.end();
        throw e;
      }
    } else {
      manualSpan.setAttributes({
        serverType: session?.serverType || 'staffSeat',
      });
    }
    params.emitter.Call.updateOne(sessionId, { status: '400' });
    manualSpan.end();
    logger.custom(
      {
        spanId,
        traceId,
        level: 'info',
        sessionId: params.sessionId,
      },
      `[${params.closeBy === 'seat' ? 'API' : 'EVENTS'}] ${spanName} success`,
    );
    return {
      sessionId,
    };
  },
);

export const SendDigitsParams = t.intersection([
  t.type({
    sessionId: t.string,
  }),
  t.union([
    t.type({
      digits: t.string,
    }),
    t.type({
      dtmfText: t.string,
    }),
  ]),
]);
export const sendDigits = createAsyncThunk(
  'call/sendDigits',
  async (params: t.TypeOf<typeof SendDigitsParams> & { emitter: TcccSdk }) => {
    const { sessionId, ...args } = await getOrPromiseReject(SendDigitsParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    if (!('digits' in args) && !('dtmfText' in args)) {
      throw new InvalidParamsError('digits', undefined);
    }
    const keyPress = 'digits' in args ? args.digits : args.dtmfText;

    await params.emitter.http.request('/ccc/pstn/sendDtmf', {
      sessionId,
      keyPress,
    });
    return {
      sessionId,
    };
  },
);
export const acceptCall = createAsyncThunk(
  'call/accept',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const logger = getLogger(params.emitter.Agent.userInfo);
    params.emitter.Call.updateOne(params.sessionId, { status: '150' });

    timer.off(params.sessionId);
    const spanName = 'tccc.Call.accept';
    const manualSpan = traceContext.tracer.startSpan(spanName, {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { spanId, traceId } = manualSpan.spanContext();
    logger.custom(
      {
        spanId,
        traceId,
        level: 'info',
        sessionId: params.sessionId,
      },
      `[API] ${spanName}`,
    );
    const { userInfo } = params.emitter.Agent;
    manualSpan.addEvent('call/accept', {
      sdkAppId: userInfo.sdkAppId,
      userId: userInfo.userId,
    });
    const { sessionId } = await getOrPromiseReject(SessionIdParams, manualSpan)(params);
    if (!sessionId) {
      const error = new InvalidParamsError('sessionId', sessionId, manualSpan);
      manualSpan.end();
      throw error;
    }
    const session = params.emitter.Call.selectOne(sessionId);
    if (!session) {
      const error = new NotFoundError('session', sessionId);
      manualSpan.recordException(error);
      manualSpan.end();
      throw error;
    }

    const trtcParams: UnifiedTRTCParams = session?.unifiedTRTCParams?.unified
      ? session.unifiedTRTCParams
      : {
          unified: false,
          userId: userInfo.userId,
          sdkAppId: userInfo.sdkAppId,
          userSig: session.userSig || '',
          roomId: session.roomId || '',
          privateMapKey: session.privateMapKey || '',
        };
    manualSpan.addEvent('trtcParams', trtcParams);
    if (
      trtcParams.userId &&
      trtcParams.sdkAppId &&
      trtcParams.userSig &&
      trtcParams.roomId &&
      trtcParams.privateMapKey
    ) {
      try {
        await checkDevice(params.emitter);
      } catch (e) {
        await giveUpSession({ sessionId: session.sessionId, deviceError: true, emitter: params.emitter });
        logger.error('accept failed', (e as DOMException).name);
        manualSpan.recordException(e as DOMException);
        manualSpan.setAttributes({
          'error.name': (e as DOMException).name,
          'error.message': (e as DOMException).message,
        });
        manualSpan.end();
        throw e;
      }
      try {
        const res = await enterRoom({
          sessionId,
          direction: Direction.callIn,
          type: session.type,
          emitter: params.emitter,
          parentSpan: manualSpan,
          ...trtcParams,
        }).then(unwrapResult);
        manualSpan.end();
        params.emitter.Call.updateOne(sessionId, { ...session, aiEnabled: res.aiEnabled });
        return { ...session, aiEnabled: res.aiEnabled };
      } catch (err) {
        const e = err as Error;
        const msg = i18next.t('EnterRoom failed, {{message}}', { message: e.message });
        logger.error('[TRTC]', msg);
        logger.report('[TRTC]', msg);
        const error = new TCCCError(msg, e);
        manualSpan.recordException(error);
        manualSpan.setAttributes({
          'error.name': e.name,
          'error.message': e.message,
        });
        manualSpan.end();
        await endCall({ sessionId: session.sessionId, closeBy: 'seat', error: error.message, emitter: params.emitter });
        throw error;
      }
    }
    const msg = i18next.t('Internal error');
    logger.error(msg);
    const error = new Error(msg);
    manualSpan.recordException(error);
    manualSpan.end();
    throw error;
  },
);

export const TransferParams = t.intersection([
  t.type({
    sessionId: t.string,
  }),
  t.union([
    t.type({
      userId: t.string,
    }),
    t.type({
      skillGroupId: t.union([t.number, stringFromNumber]),
    }),
    t.type({
      phone: t.string,
    }),
  ]),
  t.partial({
    allowQueue: t.boolean,
  }),
]);
export const transferCall = createAsyncThunk(
  'call/transfer',
  async (params: t.TypeOf<typeof TransferParams> & { emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    const spanName = 'tccc.Call.transfer';
    const manualSpan = traceContext.tracer.startSpan(spanName, {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { spanId, traceId } = manualSpan.spanContext();
    logger.custom(
      {
        spanId,
        traceId,
        level: 'info',
        session: params.sessionId,
      },
      `[API] ${spanName}`,
    );
    const { sessionId, allowQueue, ...validParams } = await getOrPromiseReject(
      TransferParams,
      manualSpan,
    )(omit(params, 'emitter'));
    await contextWithSpan(manualSpan, () =>
      params.emitter.http.request('/ccc/pstn/deflect', {
        sessionId,
        callee: validParams,
        allowQueue,
      }),
    );
    logger.custom(
      {
        spanId,
        traceId,
        level: 'info',
        session: params.sessionId,
      },
      `[API] ${spanName} success`,
    );
    try {
      await leaveRoom({ sessionId, parentSpan: manualSpan, emitter: params.emitter });
    } catch (e) {
      manualSpan.recordException(e as Error);
      manualSpan.end();
      throw e;
    }
    manualSpan.end();
    params.emitter.Call.removeOne(sessionId);
    return {
      sessionId,
    };
  },
);

const StartInternalCallParams = t.type({
  calleeUserId: t.string,
});

export const startInternalCall = createAsyncThunk(
  'call/startInternalCall',
  async (params: t.TypeOf<typeof StartInternalCallParams> & { emitter: TcccSdk }) => {
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const logger = getLogger(params.emitter.Agent.userInfo);
    const { userInfo } = params.emitter.Agent;
    await checkDevice(params.emitter);
    const manualSpan = traceContext.tracer.startSpan('tccc.Call.startInternalCall', {
      attributes: pick(userInfo, ['sdkAppId', 'userId']),
    });
    const { traceId, spanId } = manualSpan.spanContext();
    logger.custom(
      {
        traceId,
        spanId,
        level: 'info',
      },
      '[API] tccc.Call.startInternalCall',
    );
    manualSpan.addEvent('tccc.Call.startInternalCall', omit(params, 'emitter'));
    const { calleeUserId } = await getOrPromiseReject(StartInternalCallParams, manualSpan)(params);
    if (!calleeUserId) {
      const error = new InvalidParamsError('calleeUserId', calleeUserId, manualSpan);
      manualSpan.end();
      throw error;
    }
    const { sessionId, serverType } = await dialInternal({ userId: calleeUserId, emitter: params.emitter }).then(
      unwrapResult,
    );
    const type = 'internal';
    manualSpan.end();
    const session: Session = {
      sessionId,
      direction: Direction.callOut,
      type,
      serverType,
      status: '100',
    };
    params.emitter.Call.upsertOne(session);

    return session;
  },
);

const dialInternal = createAsyncThunk(
  'dialInternalCall',
  (params: { userId: string; emitter: TcccSdk }): Promise<Customer & { sessionId: string; serverType: ServerType }> =>
    new Promise(async (resolve, reject) => {
      if (!params.emitter.Agent.userInfo || !params.emitter.socket) {
        throw new Error(i18next.t('Authorization failed'));
      }
      const { userId, sdkAppId } = params.emitter.Agent.userInfo;
      const manualSpan = traceContext.tracer.startSpan('tccc.Call.startInternalCall', {
        attributes: { userId, sdkAppId },
      });
      const logger = getLogger(params.emitter.Agent.userInfo);
      const { sessionId } = await params.emitter.socket.call(
        {
          userId: params.userId,
        },
        {},
        manualSpan,
      );
      if (SessionManager.getSession({ sdkAppId, userId, sessionId })) {
        innerEmitter.emit(`dial${sessionId}`);
        const { customer, serverType } = SessionManager.getSession({ sdkAppId, userId, sessionId });
        manualSpan.end();
        resolve({
          ...customer,
          sessionId,
          serverType,
        });
      } else {
        const timer = setTimeout(() => {
          logger.info('dial timeout');
          manualSpan.recordException(new Error('dial timeout'));
          manualSpan.end();
          reject('dial timeout');
        }, 8000);
        innerEmitter.once(`dial${sessionId}`, (data: Customer & { serverType: ServerType }) => {
          clearTimeout(timer);
          manualSpan.end();
          resolve({
            ...data,
            sessionId,
          });
        });
      }
    }),
);

export const monitorCall = createAsyncThunk(
  'call/monitorCall',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk; textOnly?: boolean }) => {
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }

    // 文字监听
    if (params.textOnly) {
      await startSessionASR(params);
      const session: Session = {
        sessionId: params.sessionId,
        status: '200',
        type: 'ASRMonitor',
        direction: Direction.callOut,
      };
      params.emitter.Call.upsertOne(session);
      return session;
    }

    // 语音监听
    const { userInfo } = params.emitter.Agent;
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    params.emitter.Call.updateOne(sessionId, { status: '150' });

    const {
      userSig,
      roomId,
      privateMapKey,
      seat: seatUserId,
      unifiedTRTCParams,
    } = await params.emitter.http.request('/ccc/pstn/monitor', {
      sessionId,
    });
    const span = traceContext.tracer.startSpan('monitorCall', {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const type = 'monitor';
    const trtcParams = unifiedTRTCParams?.unified
      ? unifiedTRTCParams
      : {
          sdkAppId: userInfo.sdkAppId,
          userId: userInfo.userId,
          roomId,
          privateMapKey,
          userSig,
        };
    await enterRoom({
      sessionId,
      direction: Direction.callOut,
      type,
      parentSpan: span,
      emitter: params.emitter,
      ...trtcParams,
    });
    const session: Session = {
      sessionId,
      roomId,
      userSig,
      status: '200',
      type,
      direction: Direction.callOut,
    };
    params.emitter.Call.upsertOne(session);

    return {
      ...session,
      seatUserId,
    };
  },
);

export const exitMonitorCall = createAsyncThunk(
  'call/exitMonitor',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    const spanName = 'tccc.Call.exitMonitor';
    const manualSpan = traceContext.tracer.startSpan(spanName, {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { spanId, traceId } = manualSpan.spanContext();
    logger.custom(
      {
        spanId,
        traceId,
        level: 'info',
        sessionId: params.sessionId,
      },
      spanName,
    );
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    try {
      const session = params.emitter.Call.selectOne(sessionId);
      if (session?.type === 'ASRMonitor') {
        await stopSessionASR({ sessionId, emitter: params.emitter });
      } else {
        await leaveRoom({ sessionId, parentSpan: manualSpan, emitter: params.emitter });
      }
      params.emitter.Call.removeOne(sessionId);
    } catch (e) {
      manualSpan.recordException(e as Error);
      manualSpan.end();
      throw e;
    }
    manualSpan.end();
    return {
      sessionId,
    };
  },
);

const InviteCallParams = t.type({
  sessionId: t.string,
  phoneNumber: t.string,
});
export const inviteCall = createAsyncThunk(
  'call/inviteCall',
  async (params: t.TypeOf<typeof InviteCallParams> & { emitter: TcccSdk }) => {
    const { sessionId, phoneNumber } = await getOrPromiseReject(InviteCallParams)(params);
    const session = params.emitter.Call.selectOne(sessionId);
    if (!session) throw new NotFoundError('session', sessionId);
    const body = {
      sessionId,
      servingNumber: `0086${phoneNumber.replace(/^0086/, '')}`,
    };
    return params.emitter.http.request('/ccc/pstn/startForwardingOuter', body);
  },
);

export const cancelInviteCall = createAsyncThunk(
  'call/cancelInviteCall',
  async (params: t.TypeOf<typeof InviteCallParams> & { emitter: TcccSdk }) => {
    const { sessionId, phoneNumber } = await getOrPromiseReject(InviteCallParams)(params);
    const session = params.emitter.Call.selectOne(sessionId);
    if (!session) throw new NotFoundError('session', sessionId);
    const body = {
      sessionId,
      servingNumber: `0086${phoneNumber.replace(/^0086/, '')}`,
    };
    return params.emitter.http.request('/ccc/pstn/giveUpForwardingOuter', body);
  },
);

export const leaveCall = createAsyncThunk(
  'call/leaveCall',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    const spanName = 'tccc.Call.leaveCall';
    const manualSpan = traceContext.tracer.startSpan(spanName, {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { spanId, traceId } = manualSpan.spanContext();
    logger.custom(
      {
        spanId,
        traceId,
        level: 'info',
        sessionId: params.sessionId,
      },
      `[${spanName}] ${spanName}`,
    );
    const { sessionId } = await getOrPromiseReject(SessionIdParams, manualSpan)(params);
    const body = {
      sessionId,
    };
    try {
      await contextWithSpan(manualSpan, () => params.emitter.http.request('/ccc/pstn/leaveForwardingOuter', body));
    } catch (e) {
      manualSpan.recordException(e as Error);
      manualSpan.end();
      logger.error('leave Forwarding failed', e);
      throw e;
    }
    await leaveRoom({ sessionId, parentSpan: manualSpan, emitter: params.emitter });
    manualSpan.end();
    return {
      sessionId,
    };
  },
);

export const restoreCall = createAsyncThunk(
  'call/restoreCall',
  async (params: t.TypeOf<typeof SessionIdParams> & { emitter: TcccSdk }) => {
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    const body = {
      sessionId,
    };
    return params.emitter.http.request('/ccc/pstn/restoreForwardingOuter', body);
  },
);

export const TriggerReceiveKeyParams = t.intersection([
  t.type({
    ivrId: t.string,
    sessionId: t.string,
  }),
  t.partial({
    clientData: t.string,
    param: t.string,
  }),
]);
export const triggerReceiveKey = createAsyncThunk(
  'call/triggerReceiveKey',
  async (params: t.TypeOf<typeof TriggerReceiveKeyParams> & { emitter: TcccSdk }) => {
    const { sessionId, ivrId, clientData, param } = await getOrPromiseReject(TriggerReceiveKeyParams)(params);
    const body = {
      sessionId,
      ivrId,
      clientData,
      param,
    };
    return params.emitter.http.request('/ccc/pstn/triggerIvr', body);
  },
);

export const TriggerSelfServiceParams = t.intersection([
  t.type({
    selfId: t.number,
    sessionId: t.string,
  }),
  t.partial({
    clientData: t.string,
    param: t.string,
  }),
]);
export const triggerSelfService = createAsyncThunk(
  'call/triggerReceiveKey',
  async (params: t.TypeOf<typeof TriggerSelfServiceParams> & { emitter: TcccSdk }) => {
    const { sessionId, selfId, clientData, param } = await getOrPromiseReject(TriggerSelfServiceParams)(params);
    const body = {
      sessionId,
      selfId,
      clientData,
      param,
    };
    return params.emitter.http.request('/ccc/pstn/triggerIvr', body);
  },
);

export const SingleStepConferenceParams = t.intersection([
  t.type({
    sessionId: t.string,
    callee: t.union([
      t.type({
        userId: t.string,
      }),
      t.type({
        skillGroupId: t.number,
      }),
      t.type({
        phone: t.string,
      }),
    ]),
  }),
  t.partial({
    skillGroupId: t.string,
    servingNumberGroupIds: t.array(t.string),
    servingNum: t.string,
    uui: t.string,
    allowQueue: t.boolean,
  }),
]);
export const invite = createAsyncThunk(
  'call/invite',
  async (params: t.TypeOf<typeof SingleStepConferenceParams> & { emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    logger.custom(
      {
        sessionId: params.sessionId,
        callee: toString(params.callee),
        level: 'info',
      },
      '[API] tccc.Call.invite',
    );
    try {
      const { sessionId, callee, servingNum, servingNumberGroupIds, skillGroupId, uui, allowQueue } =
        await getOrPromiseReject(SingleStepConferenceParams)(params);
      await params.emitter.http.request('/ccc/pstn/singleStepConference', {
        sessionId,
        callee,
        skillGroupId,
        servingNum,
        servingNumberGroupIds,
        uui,
        allowQueue,
      });
      logger.custom(
        {
          sessionId: params.sessionId,
          level: 'info',
        },
        '[API] tccc.Call.invite success',
      );
    } catch (e) {
      const error = e as TCCCError;
      logger.custom(
        {
          sessionId: params.sessionId,
          message: error.message,
          code: error.code,
          level: 'error',
        },
        '[API] tccc.Call.invite failed',
      );
    }
  },
);

export const kick = createAsyncThunk(
  'call/kick',
  async (params: { sessionId: string; memberId: string; emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    logger.custom(
      {
        sessionId: params.sessionId,
        memberId: params.memberId,
        level: 'info',
      },
      '[API] tccc.Call.kick',
    );
    try {
      const { sessionId, memberId } = await getOrPromiseReject(
        t.type({
          sessionId: t.string,
          memberId: t.string,
        }),
      )(params);
      await params.emitter.http.request('/ccc/pstn/kick', {
        sessionId,
        memberId,
      });
      logger.custom(
        {
          sessionId: params.sessionId,
          memberId: params.memberId,
          level: 'info',
        },
        '[API] tccc.Call.kick success',
      );
    } catch (e) {
      const error = e as TCCCError;
      logger.custom(
        {
          sessionId: params.sessionId,
          memberId: params.memberId,
          message: error.message,
          code: error.code,
          level: 'error',
        },
        '[API] tccc.Call.kick failed',
      );
    }
  },
);

/**
 * 服务端静音 - 主持人权限
 * 带memberId就是静音成员，不带就是静音自己
 */
export const mute = createAsyncThunk(
  'call/mute',
  async (params: { sessionId: string; memberId?: string; emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    logger.custom(
      {
        sessionId: params.sessionId,
        memberId: params.memberId,
        level: 'info',
      },
      '[API] mute',
    );
    const { sessionId, memberId } = await getOrPromiseReject(
      t.intersection([t.type({ sessionId: t.string }), t.partial({ memberId: t.string })]),
    )(params);
    try {
      if (memberId) {
        await params.emitter.http.request('/ccc/pstn/muteMember', {
          sessionId,
          memberId,
        });
      } else {
        const session = params.emitter.Call.selectOne(sessionId);
        // 不支持Web端API静音
        if (session?.serverType === 'staffSeat') {
          await muteMic({ sessionId, emitter: params.emitter });
        } else {
          await params.emitter.http.request('/ccc/pstn/mute', {
            sessionId,
          });
        }
      }
      logger.info('[API] mute success');
    } catch (e) {
      logger.error('[API] mute failed', e);
      throw e;
    }
  },
);
export const unmute = createAsyncThunk(
  'call/unmute',
  async (params: { sessionId: string; memberId?: string; emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    logger.custom(
      {
        sessionId: params.sessionId,
        memberId: params.memberId,
        level: 'info',
      },
      '[API] unmute',
    );
    const { sessionId, memberId } = await getOrPromiseReject(
      t.intersection([t.type({ sessionId: t.string }), t.partial({ memberId: t.string })]),
    )(params);
    try {
      if (memberId) {
        await params.emitter.http.request('/ccc/pstn/unmuteMember', {
          sessionId,
          memberId,
        });
      } else {
        const session = params.emitter.Call.selectOne(sessionId);
        // 不支持Web端API静音
        if (session?.serverType === 'staffSeat') {
          await unmuteMic({ sessionId, emitter: params.emitter });
        } else {
          await params.emitter.http.request('/ccc/pstn/unmute', {
            sessionId,
          });
        }
      }
      logger.info('[API] unmute success');
    } catch (e) {
      logger.error('unmute failed', e);
      throw e;
    }
  },
);

export const terminateSessions = createAsyncThunk('call/terminateSessions', async (params: { emitter: TcccSdk }) => {
  const logger = getLogger(params.emitter.Agent.userInfo);
  logger.info('[API] tccc.Call.terminateSessions');
  if (!params.emitter.Agent.userInfo) {
    throw new Error(i18next.t('Authorization failed'));
  }
  const sessionIds = Object.values(SessionManager.getSessionList(params.emitter.Agent.userInfo)).filter(
    (item) =>
      item.status === statusC.ACCEPTED || (item.status === statusC.RINGING && item.direction === Direction.callOut),
  );
  logger.info('[API] tccc.Call.terminateSessions success', sessionIds);
  await Promise.all(
    sessionIds.map((item) => endCall({ emitter: params.emitter, sessionId: item.sessionId, closeBy: 'seat' })),
  );
  return 'ok';
});

export const interceptSession = createAsyncThunk(
  'call/intercept',
  async (params: { sessionId: string; emitter: TcccSdk; userId?: string }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    const manualSpan = traceContext.tracer.startSpan('tccc.Call.intercept', {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    const { traceId, spanId } = manualSpan.spanContext();
    logger.custom(
      {
        traceId,
        spanId,
        level: 'info',
        ...omitBy(params, isObject),
      },
      '[API] tccc.Call.intercept',
    );

    // 1. 先退出之前的房间
    try {
      await leaveRoom({ sessionId, parentSpan: manualSpan, emitter: params.emitter });
    } catch (e) {
      logger.info('leave room failed', e);
    }
    // 2. 发起monitor请求
    try {
      params.emitter.Call.updateOne(sessionId, { status: '150' });
      const { userSig, roomId } = await params.emitter.http.request('/ccc/pstn/monitor', {
        sessionId,
      });
      const session: Session = {
        sessionId,
        roomId,
        userSig,
        status: '200',
        type: 'phone',
        direction: Direction.callOut,
      };
      params.emitter.Call.upsertOne(session);
    } catch (e) {
      logger.warn('intercept prepare error', e);
      await leaveRoom({ sessionId, parentSpan: manualSpan, emitter: params.emitter });
      manualSpan.recordException(e as Error);
      manualSpan.end();
      throw e;
    }

    // 3. 强拆
    try {
      await params.emitter.http.request('/ccc/pstn/intercept', {
        sessionId: params.sessionId,
      });
      logger.info('[API] tccc.Call.intercept success');
    } catch (e) {
      // 强拆失败，退房
      await leaveRoom({ sessionId, parentSpan: manualSpan, emitter: params.emitter });
      logger.info('emit agent "ended" in intercept error', {
        sessionId,
      });
      params.emitter.emit('sessionEnded', {
        sessionId,
        closeBy: 'system',
        hangupType: 1,
      });
      logger.warn('[API] tccc.Call.intercept error', e);
      manualSpan.recordException(e as Error);
      manualSpan.end();
      throw e;
    }
    manualSpan.end();
    return 'ok';
  },
);

export const startSessionASR = createAsyncThunk(
  'call/startSessionASR',
  async (params: { sessionId: string; emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const manualSpan = traceContext.tracer.startSpan('tccc.Call.startASR', {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    logger.custom(
      {
        level: 'info',
        ...omitBy(params, isObject),
      },
      '[API] tccc.Call.startASR',
    );
    try {
      await params.emitter.http.request('/ccc/pstn/startSessionAsr', {
        sessionId: params.sessionId,
      });
      manualSpan.end();
      return 'ok';
    } catch (e) {
      logger.warn('startASR error', e);
      manualSpan.recordException(e as Error);
      manualSpan.end();
      throw e;
    }
  },
);

export const stopSessionASR = createAsyncThunk(
  'call/stopSessionASR',
  async (params: { sessionId: string; emitter: TcccSdk }) => {
    const logger = getLogger(params.emitter.Agent.userInfo);
    if (!params.emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    const manualSpan = traceContext.tracer.startSpan('tccc.Call.stopASR', {
      attributes: pick(params.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
    });
    logger.custom(
      {
        level: 'info',
        ...omitBy(params, isObject),
      },
      '[API] tccc.Call.stopASR',
    );
    try {
      await params.emitter.http.request('/ccc/pstn/stopSessionAsr', {
        sessionId: params.sessionId,
      });
      manualSpan.end();
      return 'ok';
    } catch (e) {
      logger.warn('stopASR error', e);
      manualSpan.recordException(e as Error);
      manualSpan.end();
      throw e;
    }
  },
);
