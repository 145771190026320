import * as t from 'io-ts';
import zip from 'lodash/zip';
import { HangupSide, HangupType, MicStatus, ServerType, Session, SessionState, SessionType } from './index';
import { SessionItem } from '../../http/apis/ccc';
import { NumberReflectMode } from '../../constants/appSettings';
import { Direction, hangupRecord } from '../../constants/sessions';
import { parseJson } from '../../utils/parseJson';
import i18next from '../../i18n/i18next.config';
import { checkSessionTypeSupported } from '../../socket/session-manager/checkSession';
import { setSessionType } from '../../socket/session-manager/setSessionProps';

export const isIMLikeType = (type?: SessionType) => {
  if (!type) return false;
  const IMLikeType = ['im'];
  return IMLikeType.includes(type);
};

const USER_TYPES = {
  phone: '0',
  seat: '1',
  wxCustomizedApp: '2',
  wxServiceAccount: '3',
  websiteChat: '4',
  wxOfficialAccount: '5',
  wxApp: '6',
  wxKf: '7',
  android: '8',
  imAgent: '10',
  WhatsApp: '11',
};
function transformTypeFromUserType(peerSource: string, sessionItem: t.TypeOf<typeof SessionItem>): SessionType {
  if (sessionItem.autoEnter) {
    return sessionItem.autoEnter;
  }
  // 优先新类型
  if (sessionItem.sessionType && checkSessionTypeSupported(sessionItem.sessionType)) {
    return setSessionType(sessionItem.sessionType);
  }
  switch (peerSource) {
    case USER_TYPES.phone:
      return 'phone';
    case USER_TYPES.seat:
      if (sessionItem.inner) return 'internal';
      return 'voip';
    case USER_TYPES.wxApp:
    case USER_TYPES.wxServiceAccount:
    case USER_TYPES.wxOfficialAccount:
    case USER_TYPES.websiteChat:
    case USER_TYPES.wxCustomizedApp:
    case USER_TYPES.wxKf:
    case USER_TYPES.imAgent:
    case USER_TYPES.WhatsApp:
      return 'im';
    default:
      return 'phone';
  }
}
const getIvrData = (session: { keyPress?: string; ivrPressLabel?: null | string[] }) => {
  if (!session.keyPress || !session.ivrPressLabel) return [];
  const ivrNumber = Array.from(session.keyPress).filter((c) => c === '*' || c === '#' || Number.isInteger(Number(c)));
  return zip(ivrNumber, session.ivrPressLabel).map(([key, label]) => ({
    key,
    label,
  }));
};
const getCallInfo = (session: t.TypeOf<typeof SessionItem>, agentUserId: string) => {
  const { members, direction, userId } = session;
  let calleeInfo;
  let callerInfo;
  // 呼入查pstn userId
  if (direction === 0) {
    callerInfo = members?.find((item) => item.userId === userId);
    calleeInfo = members?.find((item) => item.userId === agentUserId);
  }
  // 呼出查座席
  if (direction === 1) {
    callerInfo = members?.find((item) => item.userId === agentUserId);
    calleeInfo = members?.find((item) => item.userId === userId);
  }
  return {
    calleeInfo,
    callerInfo,
  };
};
const getStartTimestamp = (a = 0, b = 0) => {
  if (a !== 0 && b !== 0) {
    return a < b ? a : b;
  }
  if (a !== 0) return a;
  if (b !== 0) return b;
  return Date.now();
};
const getEndTimestamp = (a = 0, b = 0) => {
  if (a !== 0 && b !== 0) {
    return a > b ? a : b;
  }
  if (a !== 0) return a;
  if (b !== 0) return b;
};
const getDuration = (session: t.TypeOf<typeof SessionItem>, agentUserId: string) => {
  if (session.duration) return Number(session.duration);
  const { members } = session;
  if (!members) {
    return 0;
  }
  const { callerInfo, calleeInfo } = getCallInfo(session, agentUserId);
  if (!callerInfo || !calleeInfo) {
    return 0;
  }
  // 呼出未接听
  if (calleeInfo.acceptTimestamp === 0) return 0;
  const startTimestamp = getStartTimestamp(calleeInfo?.acceptTimestamp || 0, callerInfo?.acceptTimestamp || 0);
  // hangupTimestamp可能为0
  const endTimestamp =
    getEndTimestamp(callerInfo?.hungupTimestamp || 0, calleeInfo?.hungupTimestamp || 0) || startTimestamp;
  // @ts-ignore
  return (new Date(endTimestamp * 1000) - new Date(startTimestamp * 1000)) / 1000;
};
const getPeerUser = (session: t.TypeOf<typeof SessionItem>, agentUserId: string) => {
  const { members, peerSource, inner } = session;
  if (peerSource === '1' && inner) {
    const peerUser = members?.find((item) => item.userId !== agentUserId);
    return peerUser?.userId || '';
  }
};
const getTime = (timestamp?: string) => {
  const now = Math.floor(Date.now() / 1000);
  if (timestamp === undefined) return now;
  return Number(timestamp) / 1000 > now ? now : Math.floor(Number(timestamp));
};
const transformState = (state?: string) => {
  // im、phone
  if (state === 'finished') {
    return '400';
  }
  // phone
  if (state === 'ringing') {
    return '100';
  }
  if (state === 'seatJoining') {
    return '150';
  }
  // phone是inProgress和im是InProgress
  if (state === 'inProgress' || state === 'InProgress') {
    return '200';
  }
  return '400';
};

const getHoldStatus = (session: t.TypeOf<typeof SessionItem>) => {
  if (Array.isArray(session.members)) {
    return session.members.some((item) => item.state === 1);
  }
  return false;
};

const getMuteStatus = (session: t.TypeOf<typeof SessionItem>, userId: string): MicStatus => {
  if (Array.isArray(session.members)) {
    return session.members.some(
      (item) => item.userId === userId && Array.isArray(item.acceptedState) && item.acceptedState.includes('MUTE'),
    )
      ? 'off'
      : 'on';
  }
  return 'on';
};

const transformCallerCallee = (
  session: t.TypeOf<typeof SessionItem>,
  numberReflectMode: NumberReflectMode,
  type: SessionType,
) => {
  if (
    type === 'phone' &&
    (session.phone || session.protectedPhone) &&
    session.servingNumber &&
    Number.isInteger(session.direction)
  ) {
    return session.direction === Direction.callIn
      ? {
          callerPhoneNumber: NumberReflectMode.close === numberReflectMode ? session.phone : '',
          calleePhoneNumber: session.servingNumber,
          protectedCaller: [NumberReflectMode.global, NumberReflectMode.partial].includes(numberReflectMode)
            ? session.protectedPhone
            : '',
          protectedCallee: '',
        }
      : {
          calleePhoneNumber: session.phone,
          callerPhoneNumber: session.servingNumber,
          protectedCallee: [NumberReflectMode.global, NumberReflectMode.partial].includes(numberReflectMode)
            ? session.protectedPhone
            : '',
          protectedCaller: '',
        };
  }
  if (type === 'voip') {
    return {
      callee: session.servingNumber,
      caller: session.userId,
      callerPhoneNumber: session.phone,
      calleeRemark: session.servingNumberRemark,
    };
  }
  return {
    callee: '',
    caller: '',
    calleePhoneNumber: '',
    callerPhoneNumber: '',
    protectedCaller: '',
    protectedCallee: '',
  };
};
const getLocation = (session: t.TypeOf<typeof SessionItem>) => {
  const { userPhoneLocation, direction } = session;
  if (userPhoneLocation) {
    if (direction === 0) {
      return {
        callerLocation: userPhoneLocation,
      };
    }
    return {
      calleeLocation: userPhoneLocation,
    };
  }
  return {
    calleeLocation: '',
    callerLocation: '',
  };
};

const getState = (session: t.TypeOf<typeof SessionItem>): SessionState | undefined => {
  // 内部会话没有状态，只要存在，那必定是进行中会话
  if (session.inner) {
    return 'inProgress';
  }
  if (session.pre === '1' || session.state === 'ringing') {
    return 'ringing';
  }
  if (session.state === 'InProgress') {
    return 'inProgress';
  }
  if (session.state && ['inProgress', 'finished', 'seatJoining'].includes(session.state)) {
    return session.state;
  }
};

const getLastMessage = (lastMsg: { msgBody: { MsgType: string; MsgContent: any }[]; msgTimestamp: string }) => {
  try {
    if (Array.isArray(lastMsg?.msgBody) && lastMsg?.msgBody?.length) {
      const message = lastMsg?.msgBody[0];
      const now = Math.floor(Date.now() / 1000);
      return {
        type: message?.MsgType,
        isSystemMessage: true,
        from: 'administrator',
        flow: 'out',
        time: Number(lastMsg?.msgTimestamp ?? 0) > now ? now : Math.floor(Number(lastMsg?.msgTimestamp ?? 0)),
        payload: {
          text: message?.MsgType === 'TIMTextElem' ? message?.MsgContent?.Text ?? '' : '',
        },
      };
    }
  } catch (e) {
    return undefined;
  }
  return undefined;
};
/**
 * 将getActiveSession内容转化成前端sessionType
 */
export const transformActiveSessionItemToSession = (
  sessionItem: t.TypeOf<typeof SessionItem>,
  agentUserId: string,
  numberReflectMode: NumberReflectMode,
): Session & { duration: number; timestamp: number } => {
  const ivrPath = getIvrData(sessionItem);
  const peerSource = String(sessionItem.peerSource) || String(sessionItem.user_type);
  const type = transformTypeFromUserType(peerSource, sessionItem);
  const direction = sessionItem.direction || 0;
  const duration = Number(sessionItem.duration) || getDuration(sessionItem, agentUserId);
  const peerUserId = getPeerUser(sessionItem, agentUserId);
  const state = getState(sessionItem);
  const micStatus = getMuteStatus(sessionItem, agentUserId);

  const {
    userId,
    roomId,
    sessionId,
    userSig,
    privateMapKey,
    onLineState,
    wxNick,
    avatar,
    timestamp,
    remark,
    imGroupId,
    hangupSide,
    hangupType,
    clientChannelName,
    unifiedTRTCParams,
    serverType,
    isStartRealtimeAsr = false,
    channelAgentID = '',
    lastMsg = undefined,
  } = sessionItem;
  return {
    sessionId,
    type,
    peerSource,
    status: transformState(state),
    state,
    direction,
    roomId,
    userSig,
    privateMapKey,
    remark,
    // phone
    holdStatus: getHoldStatus(sessionItem),
    micStatus,
    ...transformCallerCallee(sessionItem, numberReflectMode, type),
    ...getLocation(sessionItem),
    ivrPath,
    hangupSide,
    hangupType: hangupType as unknown as HangupType,
    // internal
    userId: userId || peerUserId,
    members: undefined,
    // im
    imGroupId,
    lastMessage: getLastMessage(lastMsg),
    onLineState: onLineState === '1',
    nickname: wxNick,
    avatar,
    ...(isIMLikeType(type) ? { conversationID: `GROUP${userId}` } : {}),
    ...(sessionItem.clientData ? { clientData: parseJson(sessionItem.clientData) } : {}),

    // time
    duration,
    timestamp: getTime(timestamp),
    channelName: clientChannelName,
    unifiedTRTCParams: unifiedTRTCParams?.unified ? unifiedTRTCParams : undefined,
    serverType: serverType as ServerType,
    isStartRealtimeAsr,
    isHost: sessionItem.isHost,
    channelAgentID,
  };
};

export const transformHangupType = (
  hangupSide: HangupSide,
  hangupType?: HangupType,
  mainReason?: string,
  subReason?: string,
): { mainReason: string; subReason: string } => {
  if (hangupType && hangupType >= 100) {
    if (hangupType === 208 || hangupType === 211) {
      return {
        mainReason: hangupRecord.get(hangupType) || '',
        subReason: subReason || '',
      };
    }
    // 2xx类型挂断: 挂断方 + 挂断原因
    if (hangupType % 200 < 100 && (hangupSide === 'seat' || hangupSide === 'user')) {
      const hangupSideStr: Record<'seat' | 'user', string> = {
        user: i18next.t('user'),
        seat: i18next.t('seat'),
      };
      return { mainReason: hangupSideStr[hangupSide] + hangupRecord.get(hangupType), subReason: '' };
    }
    return { mainReason: hangupRecord.get(hangupType) || '', subReason: '' };
  }
  if (hangupType === 1) {
    if (hangupSide === 'system') {
      return { mainReason: mainReason || i18next.t('Normal termination'), subReason: subReason || '' };
    }
    return { mainReason: i18next.t('Normal termination'), subReason: subReason || '' };
  }
  if (hangupType === 2) {
    if (hangupSide === 'seat') {
      return { mainReason: i18next.t('Canceled by the seat'), subReason: subReason || '' };
    }
    return {
      mainReason: i18next.t('Canceled by the user'),
      subReason: '',
    };
  }

  return { mainReason: mainReason || '', subReason: subReason || '' };
};
