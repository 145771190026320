import memoize from 'lodash/memoize';
import TRTC from 'trtc-sdk-v5';
import logger from '../common/Logger';

export const createTRTC = memoize(
  async ({ sdkAppId, userId }: { sdkAppId: string; userId: string }) => {
    const RTCProtoLength = 'RTCPeerConnection' in window ? window.RTCPeerConnection.toString().length : 0;
    logger.debug('createTRTC', sdkAppId, userId, `RTCPeerConnection length ${RTCProtoLength}`);
    const trtc = TRTC.create();
    try {
      await new Promise((resolve, reject) => {
        const getUserMediaTimer = setTimeout(() => {
          logger.warn('getUserMedia timeout');
          reject(new Error('getUserMedia timeout'));
        }, 5000);
        trtc
          .startLocalAudio()
          .then((res) => {
            clearTimeout(getUserMediaTimer);
            return resolve(res);
          })
          .catch((e) => {
            logger.info('getUserMedia failed', e);
            clearTimeout(getUserMediaTimer);
            restartLocalAudio({ trtc, sdkAppId, userId });
            reject(e);
          });
      });
    } catch (e) {}
    return trtc;
  },
  ({ sdkAppId, userId }) => `${sdkAppId}-${userId}`,
);

export const destroyTRTC = ({ sdkAppId, userId }: { sdkAppId: string; userId: string }) => {
  logger.debug('destroyTRTC');
  const key = `${sdkAppId}-${userId}`;
  (createTRTC.cache.get(key) as Promise<TRTC>)?.then((trtc) => {
    if (trtc) {
      createTRTC.cache.delete(key);
      trtc.stopLocalAudio().finally(() => {
        trtc.destroy();
      });
    }
  });
};

/**
 * 解决麦克风权限拒绝后重新授权采集失败的问题
 */
const restartLocalAudio = ({ trtc, sdkAppId, userId }: { trtc: TRTC; sdkAppId: string; userId: string }) => {
  try {
    const name = 'microphone' as PermissionName;
    navigator.permissions.query({ name }).then((res) => {
      logger.info(`watch permission state change ${res.state}`);
      const prevState = res.state as PermissionState;
      if (prevState === 'denied') {
        res.addEventListener('change', function deviceChangeCallback(e) {
          const key = `${sdkAppId}-${userId}`;
          const trtcCache = createTRTC.cache.get(key);
          // 已经被下线销毁
          if (!trtcCache) {
            res.removeEventListener('change', deviceChangeCallback);
            return;
          }
          // @ts-ignore
          const newState = e.target?.state as PermissionState;
          logger.info(`microphone state change to ${newState}`);
          // 一开始拒绝, 重新授权需重新开始采集
          if (newState === 'granted') {
            res.removeEventListener('change', deviceChangeCallback);
            trtc
              .startLocalAudio()
              .then(() => {
                logger.info('restart local audio success');
              })
              .catch((e) => {
                logger.info('restart local audio failed', e);
              });
          }
        });
      }
    });
  } catch (e) {
    logger.info('watch restartLocalAudio error', (e as Error).message);
  }
};
